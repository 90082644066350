.table-hover tr {
    cursor: pointer;
    user-select: none;
}

.member-deactivated {
    background: repeating-linear-gradient(
            -45deg,
            #eee,
            #eee 10px,
            darken(#eee, 5%) 10px,
            darken(#eee, 5%) 20px
    ) !important;
}

.payment-method-deactivated {
    background: repeating-linear-gradient(
            -45deg,
            #eee,
            #eee 10px,
            darken(#eee, 5%) 10px,
            darken(#eee, 5%) 20px
    ) !important;
}
