// Fonts
// @import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Placeholder
@import 'placeholder';

// Topbar filter
@import 'topbar_filter';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Fontawesome
$fa-font-path: "../webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";

// Tables
@import 'tables';

// Flatpickr
@import "~flatpickr/dist/flatpickr.css";
