.member-topbar-filter {
    @extend .form-inline;
    .form-group {
        width: 100%;
        .slider,
        .custom-select,
        .input-group {
            width: 100%;
        }
    }
    @media (min-width: 1200px) {
        float: right;

        .slider,
        .form-group,
        .input-group {
            width: auto;
            .custom-select {
                max-width: 150px;
            }
        }
    }
}
